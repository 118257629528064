<template>
<div class="list_file" @click="cb" >
  ไฟล์ {{ date }} <br>
  จำนวน : {{ branch }} สาขา<br>
  จำนวน : {{ pattle }} ลาย<br>
  <div  class="check-box" >
    <div v-if="check === true" class="check" ></div>
    <div v-else class="uncheck" ></div>
  </div>
</div>
</template>

<script>
export default {
  name: "listFile",
  props:["date","date_count",'branch',"pattle","check","cb"],
  methods: {
    checked(cb) {
      console.log(cb)
    }
  }
}
</script>

<style scoped>
.list_file{
  border:2px solid #ED4E26;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.check-box{
  width: 2rem;
  height: 1.9rem;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.check{
  background:url('../assets/image/check.png');
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
.uncheck{
  background:url('../assets/image/uncheck.png');
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
</style>
