<template>
  <v-container class="grey lighten-5">
    <v-row>
      <v-col class="header-label" >
        <img @click="GotoPrev()" src="../assets/image/prev.png" /> <h2>ข้อมูลรายการจัดเรียงทอง</h2>
      </v-col>
    </v-row>
    <hr class="line-custom" >
    <v-row  >
      <v-col lg="8" xl="8" >
        <div class="date-container" align="right" >
          <date-range-picker
              v-model="dateRange"
              :auto-apply=true
              :show-dropdowns=true
              :ranges=Ranges
              :locale-data=Lang
              :date-format="dateFormat"
              @select=SetDate
          ></date-range-picker>
        </div>
        <v-row class="list-row-file" >
          <v-col lg="4" v-for="result in files" v-bind:key="result.id" >
            <ListFile
                :date=result.date
                :cb="()=> SelectFile(result.id)"
                :date_count=result.date_count
                :branch=result.branch
                :pattle=result.pattle
                :check="file_select == result.id"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="4" xl="4" class="list-row-file" >
        <div align="center" >
          <table class="table-custom" border="1" >
            <tr>
              <th>ลำดับ</th>
              <th>สาขา</th>
              <th>ลำดับสาขา</th>
              <th>ลายทอง</th>
              <th>น้ำหนัก</th>
            </tr>
            <tbody v-if="file_select" >
            <tr  v-for="(result,index) in files_data" v-bind:key="result.id"  >
              <td align="center" >{{ (index+1) }}</td>
              <td>{{ result.branch }}</td>
              <td>{{ result.branch_level }}</td>
              <td>{{ result.pattle }}</td>
              <td>{{ result.weight }}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </v-col>
    </v-row>
    <div align="center" >
    <hr class="line-custom-small" >
      <div class="result-container" >
        <div>จำนวนสาขา {{ count_branch }} สาขา</div>
        <div>ลายทอง {{ count_pattle }} ลาย</div>
      </div>
      <img @click="GotoPage()" class="next_icon" src="../assets/image/next.png"  />
    </div>
  </v-container>
</template>
<script>
// @ is an alias to /src
import ListFile from "@/components/ListFile";
import moment from "moment";


let today = new Date()
today.setHours(0, 0, 0, 0)

let yesterday = new Date()
yesterday.setDate(today.getDate() - 1)
yesterday.setHours(0, 0, 0, 0);

export default {
  name: 'Home',
  components:{
    ListFile
  },
  data: () => ({
    dateRange: {
      startDate: today,
      endDate: today,
    },
    Ranges:{
      'วันนี้': [today, today],
      'เมื่อวาน': [yesterday, yesterday],
      'ปีนี้': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
      'เดือนก่อน': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
    },
    Lang:{
      direction: 'ltr',
      format: 'mm-dd-yyyy',
      separator: ' - ',
      applyLabel: 'ตกลง',
      cancelLabel: 'ยกเลิก',
      weekLabel: 'W',
      customRangeLabel: 'เลือกช่วงเวลา',
      daysOfWeek: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ', 'ศ', 'ส'],
      monthNames: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      firstDay: 1
    },
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    file_select:"",
    files:[
      {
        id:1,
        date:"22/11/2022",
        branch:10,
        date_count:1,
        pattle:20,
      },
      {
        id:2,
        date:"22/11/2022",
        branch:10,
        date_count:2,
        pattle:20,
      },
      {
        id:3,
        date:"22/11/2022",
        branch:10,
        date_count:3,
        pattle:20,
      },
      {
        id:4,
        date:"22/11/2022",
        branch:10,
        date_count:4,
        pattle:20,
      },
      {
        id:5,
        date:"22/11/2022",
        branch:10,
        date_count:5,
        pattle:20,
      },
      {
        id:6,
        date:"22/11/2022",
        branch:10,
        date_count:6,
        pattle:20,
      },
      {
        id:7,
        date:"22/11/2022",
        branch:10,
        date_count:7,
        pattle:20,
      },
      {
        id:8,
        date:"22/11/2022",
        branch:10,
        date_count:8,
        pattle:20,
      },


    ],
    files_data:[
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },{
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },{
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },{
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },{
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },{
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },{
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },{
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },
      {
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      },{
        id:1,
        branch:'CTR9',
        branch_level:'17',
        pattle:'สี่เสา',
        weight:0.5,
      }
    ],
    count_branch:0,
    count_pattle:0,
    is_loading:false,
    verify:false,
    error_pin:false,
    pin:"",
    input:"",
  }),
  methods:{
    GotoPage() {
      this.$router.push({ path: '/checksystem/'})
    },
    GotoPrev() {
      this.$router.push({ path: '/'})
    },
    SelectFile(id){
      this.file_select = id;
      this.DemoDataList();
    },
    Verify(code){
      this.is_loading = true;
      console.log(code)
      setTimeout(() => {this.is_loading = false,this.verify = true}, 2000);
    },
    CheckPin(){
      this.is_loading = true;
    },
    onKeyPress(input){
      if(input === "Clear"){
        this.pin = "";
      }
      else if(input === "OK"){
        this.CheckPin();
      }else{
        this.pin += input;
      }
    },
    DemoData(){
      let branch = ["BBP","BC2","BCOY","BCPY","BCR1","BCS5","BCSG","BCSW","BFPR","CF2","CF4","CFR3","CFSV","CJVN","CRS","CTBN","CTBY","CTCB"];
      let pattle = ["สี่เสา","สี่เสาโปร่งปล้อง","แปดเสาปล้อง","แปดเสาตุ้งติ้ง","บิดแน่น2สี","ห่วงคู่ดอกไม้","หยาดทิพย์","สานเสื่อดอกไม้","ผ่าหวายตุ้งติ้ง","กระดูกงู","บิดสองชั้น","บิดโปร่งตัดลาย","เกล็ดดาวลงยา","มีนา","โซ่ฝรั่ง","มือเสือโคร่งคั่นปล้อง","สายนาฬิกา","มือเสือโคร่งคั่นปล้อง"];
      let weight = ["30.4","76","45.6","3.8","22.8"];
      const demo_file = [];
      const demo_select = [];
      let today = moment()
      for (let i =  1 ; i < 20 ; i++){

        //today.add(-i)

        demo_file.push({
          id:i,
          date:today.add(-i,'days').format('DD/MM/YYYY'),
          date_count:i,
          branch:(Math.floor(Math.random() * 200) + 1),
          pattle: (Math.floor(Math.random() * pattle.length) ),
        })
      }

      for (let i =  1 ; i < 50 ; i++){
        demo_select.push({
          id:i,
          branch:branch[ Math.floor(Math.random() * branch.length) ],
          pattle:pattle[ Math.floor(Math.random() * pattle.length) ],
          weight:weight[ Math.floor(Math.random() * weight.length) ],
        })
      }

      this.files = demo_file;
      this.files_data = demo_select;

    },
    DemoDataList(){
      let branch = ["BBP","BC2","BCOY","BCPY","BCR1","BCS5","BCSG","BCSW","BFPR","CF2","CF4","CFR3","CFSV","CJVN","CRS","CTBN","CTBY","CTCB"];
      let pattle = ["สี่เสา","สี่เสาโปร่งปล้อง","แปดเสาปล้อง","แปดเสาตุ้งติ้ง","บิดแน่น2สี","ห่วงคู่ดอกไม้","หยาดทิพย์","สานเสื่อดอกไม้","ผ่าหวายตุ้งติ้ง","กระดูกงู","บิดสองชั้น","บิดโปร่งตัดลาย","เกล็ดดาวลงยา","มีนา","โซ่ฝรั่ง","มือเสือโคร่งคั่นปล้อง","สายนาฬิกา","มือเสือโคร่งคั่นปล้อง"];
      let weight = ["30.4","76","45.6","3.8","22.8"];
      const demo_file = [];
      const demo_select = [];
      let today = moment()
      for (let i =  1 ; i < 20 ; i++){

        //today.add(-i)

        demo_file.push({
          id:i,
          date:today.add(-i,'days').format('DD/MM/YYYY'),
          date_count:i,
          branch:(Math.floor(Math.random() * 200) + 1),
          pattle: (Math.floor(Math.random() * pattle.length) +1),
        })
      }

      for (let i =  1 ; i < 50 ; i++){
        demo_select.push({
          id:i,
          branch:branch[ Math.floor(Math.random() * branch.length) + 1],
          pattle:pattle[ Math.floor(Math.random() * pattle.length) + 1],
          weight:weight[ Math.floor(Math.random() * weight.length) + 1],
        })
      }


      this.files_data = demo_select;

    },
  },
  mounted() {
    this.DemoData()
  }
}
</script>
<style>
.home_card{
  background: #EBEDF3;
  height: 50vh;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.home_list_btn{
  display: flex;
}
.list_item{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.table-custom{
  width: 100%;
  border-collapse: collapse;
}
.table-custom tr th{
  font-weight: 500 !important;
  color: black;
}
.table-custom tr td{
  padding: 1px;
}
.table-custom tr:nth-child(even){
  background: #EBEDF3;
}
.line-custom{
  border: 2px solid #ED4E26;
  margin-block:1rem;
}
.line-custom-small{
  border: 2px solid #ED4E26;
  margin-block:1.2rem;
  width: 30%;
}
.date-container{
  margin-bottom: 1rem;
}
.list-row-file{
  max-height: 65vh;
  overflow-y: scroll;
}
.result-container{
  width: 50%;
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
}

</style>
