<template>
  <v-container fluid="true"  class="dashboard-container">
    <div align="center" >
    <div class="header-label-dashboard" >
      <img @click="GotoPrev()" src="../assets/image/prev_white.png" />  <font class="titles" >Monitoring Mod</font>
    </div>

    </div>
    <v-row>
      <v-col lg="8" >
        <v-row>
          <v-col>
            <div class="actual" >
              <div class="flex-row" >
                <div >
                  <font class="label" >Actual Performance</font><br>
                  <div align="center" class="label_container green-font" >
                  <font class="value large_font" >100</font><br>
                  <font class="value" >ชั้น/ชั่วโมง</font>
                  </div>
                </div>
                <div >
                  <font class="label" >STD Performance</font><br>
                  <div align="center" class="label_container" >
                  <font class="value large_font" >100</font><br>
                  <font class="value" >ชั้น/ชั่วโมง</font>
                  </div>
                </div>
              </div>
              <div class="actual-row black-font" align="center" >
                <span class="bg-label" >100</span> % Actual
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="actual-plan" >
              <div class="label" align="center" >Actual Plan</div>
              <div class="large_font" align="center" >
                500/1000 <span class="small-font" >ชิ้น</span>
              </div>
              <div align="center" >
                <div class="progress_bar" >
                  <div class="percent" :style="{width:((is_active / branch_total)*100)+'%' }" ></div>
                </div>
                <font class="yellow-font" > 50%</font>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="actual-time" >
              <div class="label" align="center" >Time</div>
              <div class="actual-time-list" >
                <div class="large_font black-font" >Start</div>
                <div class="large_font" >14.00</div>
              </div>
              <div class="actual-time-list" >
                <div class="large_font black-font" >Finish</div>
                <div class="large_font yellow-color" >16.00</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <h1>Branch</h1>
        </v-row>


        <v-row>
          <v-col>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
          </v-col>
          <v-col>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
          </v-col>
          <v-col>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
          </v-col>
          <v-col>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
            <div class="list-status-machine" >
              <ListStatus
                  status="wait"
              />
              <ListStatus
                  status="wait"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="list-description" >
              <div class="box green" ></div>
              <div>
                ครบตามแผน (Achieve)
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="list-description" >
              <div class="box yellow" ></div>
              <div>
                ยังไม่ครบตามแผน (On Process)
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="list-description" >
              <div class="box red" ></div>
              <div>
                สินค้าไม่มีในแผน (Reject)
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="list-description" >
              <div class="box white" ></div>
              <div>
                ไม่มีสาขา (No Branch)
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="4" >
        Real time result
        <table class="table-custom" >
          <tr>
            <th>SKU</th>
            <th>Plan<br>(pcs)</th>
            <th>Result<br>(pcs)</th>
          </tr>
          <tr>
            <td>AKKI</td>
            <td>50</td>
            <td>50</td>
          </tr>
          <tr>
            <td>AHKP</td>
            <td>150</td>
            <td>100</td>
          </tr>
          <tr>
            <td>BPV</td>
            <td>150</td>
            <td>100</td>
          </tr>
          <tr>
            <td>A4S</td>
            <td>100</td>
            <td>60</td>
          </tr>
          <tr>
            <td>B4S</td>
            <td>150</td>
            <td>100</td>
          </tr>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// @ is an alias to /src

import ListStatus from "@/components/ListStatus";

let today = new Date()
today.setHours(0, 0, 0, 0)

let yesterday = new Date()
yesterday.setDate(today.getDate() - 1)
yesterday.setHours(0, 0, 0, 0);

export default {
  name: 'Home',
  components:{
    ListStatus
  },
  data: () => ({
    dateRange: {
      startDate: today,
      endDate: today,
    },
    Ranges:{
      'วันนี้': [today, today],
      'เมื่อวาน': [yesterday, yesterday],
      'ปีนี้': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
      'เดือนก่อน': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
    },
    Lang:{
      direction: 'ltr',
      format: 'mm-dd-yyyy',
      separator: ' - ',
      applyLabel: 'ตกลง',
      cancelLabel: 'ยกเลิก',
      weekLabel: 'W',
      customRangeLabel: 'เลือกช่วงเวลา',
      daysOfWeek: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ', 'ศ', 'ส'],
      monthNames: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      firstDay: 1
    },
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    file_select:"",
    files:[
      {
        ip:"192.168.1.0",
        status:"wait",
        branch:"CTR9",
        pattle:10,
        amount:1,
      },
      {
        ip:"192.168.1.0",
        status:"deactive",
        branch:"CTR9",
        pattle:10,
        amount:1,
      },
      {
        ip:"192.168.1.0",
        status:"active",
        branch:"CTR9",
        pattle:10,
        amount:1,
      },
    ],
    count_branch:10,
    count_pattle:10,
    is_loading:false,
    is_active:0,
    branch_total:20,
    verify:false,
    error_pin:false,
    pin:"",
    input:"",
    progress_percent:"",
  }),
  methods:{
    SelectFile(id){
      this.file_select = id;
    },
    Verify(code){
      this.is_loading = true;
      console.log(code)
      setTimeout(() => {this.is_loading = false,this.verify = true}, 2000);
    },
    CheckPin(){
      this.is_loading = true;
    },
    onKeyPress(input){
      if(input === "Clear"){
        this.pin = "";
      }
      else if(input === "OK"){
        this.CheckPin();
      }else{
        this.pin += input;
      }
    },
    DemoMachine(){
      const demo = [];

      for (let i =  1 ; i < 70 ; i++){

        //today.add(-i)

        demo.push({
          id:i,
          ip:"192.168.1."+i,
          status:"wait",
          branch:"-",
          amount:"-",
        })
      }

      this.files = demo;
      this.CheckSystem()
    },
    CheckSystem(){

      let status = ["active","deactive"];
      let branch = ["BBP","BC2","BCOY","BCPY","BCR1","BCS5","BCSG","BCSW","BFPR","CF2","CF4","CFR3","CFSV","CJVN","CRS","CTBN","CTBY","CTCB"];
      let count =  0;


     const Interval = setInterval(()=>{
            if(status[Math.floor(Math.random() * status.length)] === 'active'){
              this.files[count].status = "active";
              this.files[count].branch = branch[ Math.floor(Math.random() * branch.length) ];
              this.files[count].amount = Math.floor(Math.random() * 20);
              this.is_active++;
            }else{
              this.files[count].status = "deactive";
            }

            if(this.is_active === this.branch_total){
              clearInterval(Interval);
            }else {
              count++
            }

      }
          ,3000);

    },
    GotoPrev() {
      this.$router.push({ path: '/checksystem/'})
    },
  },
  mounted() {
    this.DemoMachine()
  }
}
</script>
<style  >
.home_card{
  background: #EBEDF3;
  height: 50vh;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.home_list_btn{
  display: flex;
}
.list_item{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.table-custom{
  width: 100%;
  border-collapse: collapse;
  border: #EBEDF3 solid 1px;
}
.table-custom tr{
  background: #4caf50;
}
.table-custom tr th{
  font-weight: 500 !important;
  color: #faf7f7;
  border: #EBEDF3 solid 1px;
}
.table-custom tr td{
  padding: 1px;
}
.table-custom tr:nth-child(even){
  background: #EBEDF3;
  color: black;
}
.line-custom{
  border: 2px solid #ED4E26;
  margin-block:1rem;
}
.line-custom-small{
  border: 2px solid #ED4E26;
  margin-block:1.2rem;
  width: 30%;
}
.date-container{
  margin-bottom: 1rem;
}
.list-row-file{
  max-height: 60vh;
  overflow-y: scroll;
}
.result-container{
  width: 50%;
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
}
.progress_bar{
  width: 70%;
  height: 1rem;
  background: #000000;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}
.progress_bar .percent{
  position: absolute;
  left: 0;
  height: 100%;
  background: #38ff62;
}
.percent_text{
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-weight: bold;
}
.titles{
  color: blue;
  font-size: 2rem;
  font-weight: bold;
}
.dashboard-container{
  background: black;
  color: #EBEDF3;
}
.actual{
  background: #ED7D31;
  padding: 0.5rem;
  border-radius: 10px;
  height: 10rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.actual-plan{
  background: #ED7D31;
  padding: 0.5rem;
  border-radius: 10px;
  height: 10rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.actual-time{
  background: #ED7D31;
  padding: 0.5rem;
  border-radius: 10px;
  height: 10rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.flex-row{
  display: flex;
  justify-content: space-evenly;
}
.label{
  color: black;
}
.large_font{
  font-size: 2rem;
  font-weight: bold;
}
.label_container{
  padding-top: 1rem;
  line-height: 20px;
  padding-bottom: 0.5rem;
}
.bg-label{
  background: #4caf50;
  padding: 0.2rem;
}
.green-font{
  color: #43fd46;
}
.yellow-font{
  color: #FEFE00;
  font-size: 1.5rem;
  font-weight: 300;
}
.yellow-color{
  color: #FEFE00;
}
.black-font{
  color: #000000;
}
.small-font{
  font-size: 1rem;
}
.actual-time-list{
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.list-status-machine{
  display: flex;
  width: 15rem;
}
.list-description{
  display: flex;
  align-content: center;
  align-items: center;
  gap: 10px;
}
.box{
  width: 2rem;
  height: 2rem;
}
</style>
