<template >
  <v-app  >
    <div class="container-fluid"  >
      <v-main>
        <v-container fluid="true" >
          <router-view/>
        </v-container>
      </v-main>
    </div>
  </v-app>




</template>

<script>

import {baseUrl} from "./const/api";
import { StartLotto} from "./lib/controller";
import {ResponseData} from "./lib/utility";
export default {
  name: 'App',

  components: {},
  data () {
    return {
      date_start:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date_end:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      time_start:"",
      time_end:"",
      dialog:false,
      baseUrl:baseUrl,
      auth: localStorage.getItem('user') ? true:false,
      user: JSON.parse(localStorage.getItem('user')),
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      mini: true,
    }
  },
  methods:{
    Logout(){
      localStorage.removeItem('user');
      window.location = "/login"
    },
    CheckLogin(){
      /*
      console.log(this.$router.currentRoute.path)
      const UserData = localStorage.getItem('user');
      if(!UserData && this.$router.currentRoute.path !== "/login"){
       window.location = "/login"
      }else{
        console.log("Nologin")
      }

       */
    },
    async StartLottory(){
      //alert("StartLottory")

      let Swal = this.$swal;

      let formData = new FormData();
      formData.append("date_start",this.date_start)
      formData.append("time_start",this.time_start)
      formData.append("date_end",this.date_end)
      formData.append("time_end",this.time_end)
      formData.append("merchant_id",this.user.merchant_id)
      formData.append("users_id",this.user.id)

    await StartLotto(formData,(response)=>  {
    console.log(response)
    const { status ,data ,statusText  } = ResponseData(response)
      console.log(status)
      console.log(data)
      console.log(statusText)


      switch (status){
            case 200 :

              this.$toast.open({
                message: 'เริ่มงวดใหม่สำเร็จ',
                type: "success",
                duration: 2000,
                dismissible: true
              })

                setTimeout(()=>{
                  window.location = "/sale"
                },2000)

              break;
            case 422 :

              for (const [key, value] of Object.entries(data)) {
                console.log(`${key}: ${value}`);

               this.$toast.open({
                  message: `${value}`,
                  type: "warning",
                  duration: 10000,
                  dismissible: true
                })

              }

            break;
            case 409:

              this.$toast.open({
                message: data.msg,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

              break;
            case 500 :


              Swal({
                icon: "error",
                title: statusText,
                text: data.message,
              })

            break;
      }

    });


     /* console.log(results)

      if(error){
        Swal({
          icon: "error",
          title: results.message,
        })
      }

      if(status === 200){
        Swal({
          icon: 'success',
          title: 'Success',
        })
      }else{
        Swal({
          icon: results.icon,
          title: results.message,
        })
      }*/

    }
  },

  mounted() {
    this.CheckLogin()
  },
  created() {
    //console.log(process.env)
  }

};
</script>
<style>
.footer_custom{
  justify-content: center;
}
.v-toolbar__content{
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: space-between;
}
.date_time{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.date_time_font{
  color: #000000;
  font-size: 1.2rem;
}
</style>
