<template>
      <v-container class="custom-container" >
        <div v-if="!verify" class="custom-card-barcode" >
          <img class="img-logo" v-on:click="Verify('1234')" src="../assets/image/logo.png">
          <font v-if="!is_loading" class="text-barcode" >ทำการสแกน BARCODE เพื่อเข้าสู่ระบบ</font>
          <img v-else src="../assets/image/loading.svg" />
        </div>
        <div v-else class="verify-container" >
          <div class="show_number" v-if="verify" >
            <font class="verifiled"  >กรอกรหัสยืนยันตัวตน</font>
            <input type="password" disabled class="password-input" maxlength="6"  v-model="pin" >
            <font v-if="error_pin" class="verifiled-error" >รหัสยืนยันไม่ถูกต้อง</font>
            <img  v-if="is_loading" src="../assets/image/loading2.svg" />
          </div>
          <div v-if="verify" class="keyboard_container-number" >
          <Keyboard @onChange="onChange"
                    @onKeyPress="onKeyPress" :input="input"
          />
          </div>


        </div>
      </v-container>
</template>
<script>
// @ is an alias to /src
import Keyboard from "@/components/KeyNumber";

export default {
  name: 'Home',
  components: {
    Keyboard
  },
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    is_loading:false,
    verify:false,
    error_pin:false,
    pin:"",
    input:"",
  }),
  methods:{
    Verify(code){
      this.is_loading = true;
      console.log(code)
      setTimeout(() => {this.is_loading = false,this.verify = true}, 2000);

    },
    CheckPin(){
      this.is_loading = true;
      setTimeout(() => {
        this.$router.push({ path: '/listFile/'})
      }, 2000);
    },
    onKeyPress(input){
      if(input === "Clear"){
        this.pin = "";
      }
      else if(input === "OK"){
        this.CheckPin();
      }else{
        this.pin += input;
      }
    },
  }
}
</script>
<style>
.home_card{
  background: #EBEDF3;
  height: 50vh;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.home_list_btn{
  display: flex;
}
.list_item{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
}
.img-logo{
  width: 30rem;
}
</style>
