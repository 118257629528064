<template>
  <v-container class="grey lighten-5">
    <v-row>
      <v-col class="header-label" >
        <img @click="GotoPrev()" src="../assets/image/prev.png" /> <h2>ข้อมูลรายการจัดเรียงทอง</h2>
      </v-col>
    </v-row>
    <hr class="line-custom" >
    <v-row  >
      <v-col lg="8" xl="8" >
        <div class="date-container" align="right" >
          <date-range-picker
              v-model="dateRange"
              :auto-apply=true
              :show-dropdowns=true
              :ranges=Ranges
              :locale-data=Lang
              :date-format="dateFormat"
              @select=SetDate
          ></date-range-picker>
        </div>
        <v-row class="list-row-file" >
          <v-col lg="4" v-for="result in files" v-bind:key="result.id" >
            <ListMachine
                :ip=result.ip
                :status=result.status
                :branch=result.branch
                :pattle=result.pattle
                :amount=result.amount
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="4" xl="4" class="list-row-file" >
        <div align="center" >
          <table class="table-custom" border="1" >
            <tr>
              <th>ลำดับ</th>
              <th>สาขา</th>
              <th>จำนวน</th>
              <th>IP เครื่อง</th>
              <th>สถานะ</th>
            </tr>
            <tbody v-if="files" >
            <tr  v-for="(result,index) in files" v-bind:key="result.id"  >
              <td align="center" >{{ (index+1) }}</td>
              <td>{{ result.branch }}</td>
              <td>{{ result.amount }}</td>
              <td>{{ result.ip }}</td>
              <td>{{ result.status }}</td>
            </tr>
            </tbody>
          </table>
        </div>

      </v-col>
    </v-row>
    <div align="center" >
    <hr class="line-custom-small" >
      <div class="result-container" >
        <div>จำนวนสาขา {{ is_active }}/{{ branch_total }} สาขา</div>
      </div>
      <div class="progress_bar" >
        <div class="percent" :style="{width:((is_active / branch_total)*100)+'%' }" ></div>
        <div class="percent_text" > กำลังตรวจสอบสถานะ &nbsp; {{ Math.round((is_active / branch_total)*100)  }} % </div>
      </div>
      <img @click="GotoPage()" class="next_icon" v-if="((is_active / branch_total)*100) === 100" src="../assets/image/next.png"  />
    </div>
  </v-container>
</template>
<script>
// @ is an alias to /src
import ListMachine from "@/components/ListMachine";


let today = new Date()
today.setHours(0, 0, 0, 0)

let yesterday = new Date()
yesterday.setDate(today.getDate() - 1)
yesterday.setHours(0, 0, 0, 0);

export default {
  name: 'Home',
  components:{
    ListMachine
  },
  data: () => ({
    dateRange: {
      startDate: today,
      endDate: today,
    },
    Ranges:{
      'วันนี้': [today, today],
      'เมื่อวาน': [yesterday, yesterday],
      'ปีนี้': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
      'เดือนก่อน': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
    },
    Lang:{
      direction: 'ltr',
      format: 'mm-dd-yyyy',
      separator: ' - ',
      applyLabel: 'ตกลง',
      cancelLabel: 'ยกเลิก',
      weekLabel: 'W',
      customRangeLabel: 'เลือกช่วงเวลา',
      daysOfWeek: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ', 'ศ', 'ส'],
      monthNames: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      firstDay: 1
    },
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    file_select:"",
    files:[
      {
        ip:"192.168.1.0",
        status:"wait",
        branch:"CTR9",
        pattle:10,
        amount:1,
      },
      {
        ip:"192.168.1.0",
        status:"deactive",
        branch:"CTR9",
        pattle:10,
        amount:1,
      },
      {
        ip:"192.168.1.0",
        status:"active",
        branch:"CTR9",
        pattle:10,
        amount:1,
      },
    ],
    count_branch:10,
    count_pattle:10,
    is_loading:false,
    is_active:0,
    branch_total:20,
    verify:false,
    error_pin:false,
    pin:"",
    input:"",
    progress_percent:"",
  }),
  methods:{
    GotoPage() {
      this.$router.push({ path: '/dashboard/'})
    },
    GotoPrev() {
      this.$router.push({ path: '/listFile'})
    },
    SelectFile(id){
      this.file_select = id;
    },
    Verify(code){
      this.is_loading = true;
      console.log(code)
      setTimeout(() => {this.is_loading = false,this.verify = true}, 2000);
    },
    CheckPin(){
      this.is_loading = true;
    },
    onKeyPress(input){
      if(input === "Clear"){
        this.pin = "";
      }
      else if(input === "OK"){
        this.CheckPin();
      }else{
        this.pin += input;
      }
    },
    DemoMachine(){
      const demo = [];

      for (let i =  1 ; i < 70 ; i++){

        //today.add(-i)

        demo.push({
          id:i,
          ip:"192.168.1."+i,
          status:"wait",
          branch:"-",
          amount:"-",
        })
      }

      this.files = demo;
      this.CheckSystem()
    },
    CheckSystem(){

      let status = ["active","deactive"];
      let branch = ["BBP","BC2","BCOY","BCPY","BCR1","BCS5","BCSG","BCSW","BFPR","CF2","CF4","CFR3","CFSV","CJVN","CRS","CTBN","CTBY","CTCB"];
      let count =  0;


     const Interval = setInterval(()=>{
            if(status[Math.floor(Math.random() * status.length)] === 'active'){
              this.files[count].status = "active";
              this.files[count].branch = branch[ Math.floor(Math.random() * branch.length) ];
              this.files[count].amount = Math.floor(Math.random() * 20);
              this.is_active++;
            }else{
              this.files[count].status = "deactive";
            }

            if(this.is_active === this.branch_total){
              clearInterval(Interval);
            }else {
              count++
            }

      }
          ,1000);

    },
  },
  mounted() {
    this.DemoMachine()
  }
}
</script>
<style>
.home_card{
  background: #EBEDF3;
  height: 50vh;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.home_list_btn{
  display: flex;
}
.list_item{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.table-custom{
  width: 100%;
  border-collapse: collapse;
}
.table-custom tr th{
  font-weight: 500 !important;
  color: black;
}
.table-custom tr td{
  padding: 1px;
}
.table-custom tr:nth-child(even){
  background: #EBEDF3;
}
.line-custom{
  border: 2px solid #ED4E26;
  margin-block:1rem;
}
.line-custom-small{
  border: 2px solid #ED4E26;
  margin-block:1.2rem;
  width: 30%;
}
.date-container{
  margin-bottom: 1rem;
}
.list-row-file{
  max-height: 60vh;
  overflow-y: scroll;
}
.result-container{
  width: 50%;
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
}
.progress_bar{
  width: 70%;
  height: 2rem;
  background: #FFC79E;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.progress_bar .percent{
  position: absolute;
  left: 0;
  height: 100%;
  background: #FF6F07;
}
.percent_text{
  width: 100%;
  height: 100%;
  background: none;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-weight: bold;
}
</style>
