<template>
  <div class="list_machine" >
    <div>
      <img class="machine-icon" src="../assets/image/machine.png">
    </div>
    <div>
      ไอพี: {{ ip }}<br>
      สถานะ: {{ status }}<br>
      สาขา: {{ branch }}<br>
      จำนวน: {{ amount }}<br>
    </div>
    <div :class="status" ></div>
  </div>
</template>

<script>
export default {
  name: "ListMachine",
  props:["ip","status",'branch',"pattle","amount"],
  methods: {
    checked(cb) {
      console.log(cb)
    }
  }
}
</script>

<style scoped>

.list_machine{
  display: flex;
  gap: 5px;
  border: 2px solid #ED4E26;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  align-content: center;
  align-items: flex-start;
  font-size: 0.9rem;
}
.active{
  background: #4caf50;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
}
.wait{
  background: #ffcf32;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
}
.deactive{
  background: #ff0000;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
}
.machine-icon{
  width: 4rem;
}
</style>
