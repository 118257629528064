const axios = require('axios').default;
console.log(process.env.NODE_ENV)
console.log(process.env)
const host = (location.host).split(":");
let api;
let baseUrl;
let service_api;
let position_amount;
let machine_number;




if(process.env.NODE_ENV.search("local") === 0){

    api =  ("http://"+host[0]+":"+process.env.VUE_APP_API_PORT).toString()+"/api/";
    baseUrl = ("http://"+host[0]+":"+process.env.VUE_APP_API_PORT).toString()+"";
    service_api = "http://167.71.218.246:3000/";
    position_amount = process.env.VUE_APP_SLOT;
    machine_number = process.env.VUE_APP_MACHINE;

}else{

     api =  process.env.VUE_APP_API;
     baseUrl =  process.env.VUE_APP_BASE;
     service_api = process.env.VUE_APP_API_SERVICE;
     position_amount = process.env.VUE_APP_SLOT;
     machine_number = process.env.VUE_APP_MACHINE;
}


let prod = true;
api =  prod ? "https://lottoapi.commsk.dev/api/" : ("http://"+host[0]+":"+process.env.VUE_APP_API_PORT).toString()+"/api/";
baseUrl = ("http://"+host[0]+":"+process.env.VUE_APP_API_PORT).toString()+"";
service_api = "http://167.71.218.246:3000/";
position_amount = process.env.VUE_APP_SLOT;
machine_number = process.env.VUE_APP_MACHINE;



console.log(machine_number)

const instance = axios.create({
    baseURL: api,
    timeout: 5000,
    headers: {
        'X-Custom-Header': 'foobar',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    }
});

const instance_service = axios.create({
    baseURL: service_api,
    timeout: 5000,
    headers: {
        'X-Custom-Header': 'foobar',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    }
});


/*
const instance_machine = axios.create({
    baseURL: api_machine,
    timeout: 500000,
    useCredentails: true,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        'X-Custom-Header': 'foobar'
    }
});
*/


const API = {
    Auth: '/auth_user',
    Lotto: '/lotto',
    LottoNumber: '/lotto_number',
    LottoNumberLimit: '/lotto_number_limit',
    Employee: '/employee',
}

const SERVICE = {
    CHECK : 'check',
    DEL : 'del_product',
    RESTOER : 'restore_product',
    UPDATE : 'update_product',
    UPDATECategory : 'update_category',
    UPDATEUser : 'update_users',
}



export { SERVICE,API,instance,baseUrl,position_amount,instance_service,service_api };
