<template>
  <div class="list_status" >
    <div class="status" >
      <div v-if="status === 'wait' " >
        -
      </div>
      <div v-else >
        {{ branch }}
        {{ amount }}
      </div>
    </div>
    <div>
      <div class="box" >{{ ip }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListStatus",
  props:["ip","status",'branch',"amount"],
  methods: {
    checked(cb) {
      console.log(cb)
    }
  }
}
</script>

<style scoped>
.list_status{
  width: 25em;
  height: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.list_status:nth-child(even){
  width: 25rem;
  height: 3rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}
.list_status div{
  padding: 0.2rem;
  flex: 1;
  display: flex;
  align-content: center;
  align-items: center;
}
.list_status:nth-child(even) div{
  float: right;
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
}
.box{
  width: 100%;
  height: 100%;
  background: #4caf50;
}
</style>
